"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var context_1 = require("../contexts/appConfiguration/context");
var requests_1 = require("../utilities/requests");
var requests_2 = require("../utilities/requests");
function useRequests() {
    var config = (0, context_1.useAppConfig)();
    var tournamentApiUrl = config.tournamentApiUrl, winnersWidgetApiUrl = config.winnersWidgetApiUrl, rubyApiUrl = config.rubyApiUrl, rubyCdnDomain = config.rubyCdnDomain, miniGamesApi = config.miniGamesApi;
    return {
        getLeaderboards: (0, requests_1.getLeaderboards)(tournamentApiUrl),
        getWinnersWidgetData: (0, requests_2.getWinnersWidgetData)(winnersWidgetApiUrl),
        getFeatureConfigs: (0, requests_2.getFeatureConfigs)(rubyApiUrl),
        getFeatureState: (0, requests_2.getFeatureState)(rubyApiUrl),
        getGameImage: (0, requests_2.getGameImage)(rubyCdnDomain),
        getMiniGamesList: (0, requests_2.getMiniGamesList)(miniGamesApi),
        getMiniGame: (0, requests_2.getMiniGame)(miniGamesApi),
        startMiniGame: (0, requests_2.startMiniGame)(miniGamesApi),
        cashInRewardMiniGame: (0, requests_2.cashInRewardMiniGame)(miniGamesApi),
    };
}
exports.default = useRequests;
