import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { WheelPage } from "./pages/Wheel";
import { Leaderboards } from "./pages/Leaderboards";
import React, { useEffect } from "react";
import useQuery from "./hooks/useQuery";
import { useMessages } from "./contexts/messages/context";
import { MessageTypes } from "@phantom/minigames-integration";
import { ILeaderboardsOptions, IWofOptions } from "@phantom/ehub-components";
import { getLeaderboardsPayload, getWofPayload } from "./utils/helpers";

const Main = () => {
  const isTest = useQuery("test");
  const { pathname } = useLocation();
  const { sendLocalMessage } = useMessages();
  useEffect(() => {
    if (isTest === "true") {
      switch (pathname) {
        case "/leaderboards":
          sendLocalMessage<ILeaderboardsOptions>({
            type: MessageTypes.EHUB_SHOW_COMPONENT,
            payload: getLeaderboardsPayload(),
          });
          break;
        case "/wheel-of-fortune":
          sendLocalMessage<IWofOptions>({
            type: MessageTypes.EHUB_SHOW_COMPONENT,
            payload: getWofPayload(),
          });
      }
    }
  }, [isTest, pathname]);
  return (
    <Routes>
      <Route path="/wheel-of-fortune" element={<WheelPage />} />
      <Route path="/leaderboards" element={<Leaderboards />} />
      <Route path="/not-found" element={<div />} />
      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  );
};

export default Main;
