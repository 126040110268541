import "./styles.scss";
import { FC, useEffect, useState } from "react";
import { IMiniGameUpdateReward } from "@phantom/ehub-components";
import { useMessages } from "../../contexts/messages/context";
import { MessageTypes } from "@phantom/minigames-integration";
// import {inspect} from "util";
// import styles = module

interface IProps {
  prize: IMiniGameUpdateReward;
}

const Spin247WinModal: FC<IProps> = ({ prize }) => {
  const { sendMessage } = useMessages();
  const [disabled, setDisabled] = useState(false);
  return (
    <div className={"modal"}>
      <div className="opaque"></div>
      <div className={"modal-wrapper"}>
        <div className={"content"}>
          {prize.title && prize.title !== "No Win" && (
            <div className={"successWrapper"}>
              <div className={"title"}>
                <h2>CONGRATULATIONS</h2>
              </div>

              <div className={"body"}>
                <p className={"first"}>YOU WON</p>
                <p className={"second"}>{prize.title}</p>
                <p className={"third"}>
                  <span>BONUS</span>
                </p>
              </div>

              <div className={"gift"}>
                <p>THE GIFT IS ALREADY IN YOUR ACCOUNT</p>
              </div>

              <div className={"button-wrapper"}>
                <button
                  disabled={disabled}
                  onClick={() => {
                    sendMessage({ type: "REDIRECT_HOME" });
                    setDisabled(true);
                  }}
                  className={"button"}
                >
                  LET'S PLAY
                </button>
              </div>
            </div>
          )}
          {prize.title && prize.title === "No Win" && (
            <>
              <h2>Try again tomorrow</h2>
              <button
                disabled={disabled}
                onClick={() => {
                  sendMessage({ type: "REDIRECT_HOME" });
                  setDisabled(true);
                }}
                className={"button"}
              >
                Back
              </button>
            </>
          )}
        </div>
        <div className={"bottom-container"}>
          <p>
            Keep playing and you'll get a gift tomorrow too! In case you have
            any questions or problems, contact us.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Spin247WinModal;
