"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
function distance(x1, y1, x2, y2) {
    return Math.sqrt(Math.pow((x2 - x1), 2) + Math.pow((y2 - y1), 2));
}
var Wheel = (0, react_1.forwardRef)(function (_a, ref) {
    var options = _a.options, _b = _a.width, width = _b === void 0 ? 500 : _b, _c = _a.startAngle, startAngle = _c === void 0 ? 0 : _c, _d = _a.insideRadius, insideRadius = _d === void 0 ? 10 : _d, _e = _a.borderWidth, borderWidth = _e === void 0 ? 15 : _e, _f = _a.borderColor, borderColor = _f === void 0 ? "black" : _f, 
    // textFont = 'bold 12px Helvetica, Arial',
    _g = _a.sliceColor, 
    // textFont = 'bold 12px Helvetica, Arial',
    sliceColor = _g === void 0 ? "black" : _g, _h = _a.winnerSliceColor, winnerSliceColor = _h === void 0 ? "red" : _h, _j = _a.showIndicator, showIndicator = _j === void 0 ? false : _j, _k = _a.drawAngle, drawAngle = _k === void 0 ? 90 : _k, _l = _a.spinSpeed, spinSpeed = _l === void 0 ? 10 : _l, spinAction = _a.spinAction, _m = _a.onSpinStop, onSpinStop = _m === void 0 ? function () { } : _m, _o = _a.imageFrame, imageFrame = _o === void 0 ? "" : _o, _p = _a.skinPath, skinPath = _p === void 0 ? "" : _p, _q = _a.rootClass, rootClass = _q === void 0 ? "" : _q, _r = _a.imageFrameClass, imageFrameClass = _r === void 0 ? "" : _r, _s = _a.prizeIndex, prizeIndex = _s === void 0 ? -1 : _s, _t = _a.children, children = _t === void 0 ? null : _t, _u = _a.loading, loading = _u === void 0 ? false : _u;
    var canvasRef = (0, react_1.useRef)(null);
    var arc = Math.PI / (options.length / 2);
    // const arcDegree = (arc * 180) / Math.PI;
    var textRadius = 0;
    var _v = (0, react_1.useState)(), spinTimeout = _v[0], setSpinTimeout = _v[1];
    var _w = (0, react_1.useState)(), spinLoadTimeout = _w[0], setSpinLoadTimeout = _w[1];
    var currentAngle = (0, react_1.useRef)(startAngle);
    var winIndex = (0, react_1.useRef)(-1);
    var spinning = (0, react_1.useRef)(false);
    var center = { x: width / 2, y: width / 2 };
    var finishedAnimation = (0, react_1.useRef)(false);
    var spinLoading = (0, react_1.useRef)(loading);
    var winPCx = (0, react_1.useRef)(0);
    var winPCy = (0, react_1.useRef)(0);
    var speed = (0, react_1.useRef)(0.01);
    var realPrizeIndex = (0, react_1.useRef)(prizeIndex);
    var easeIn = (0, react_1.useRef)(false);
    var canStop = (0, react_1.useRef)(false);
    var winnerIndex = (0, react_1.useRef)(-1);
    var alreadyLoading = (0, react_1.useRef)(false);
    var rotationDegrees = (0, react_1.useRef)(0);
    var remainingDegrees = (0, react_1.useRef)(0);
    var idlingDegrees = (0, react_1.useRef)(0);
    var idleDirection = (0, react_1.useRef)(false);
    var idlingInterval = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        realPrizeIndex.current = prizeIndex;
    }, [prizeIndex]);
    function stopRotateWheel() {
        var _a;
        if (canvasRef) {
            spinning.current = false;
            var ctx = (_a = canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current) === null || _a === void 0 ? void 0 : _a.getContext("2d");
            if (ctx) {
                spinTimeout && clearTimeout(spinTimeout);
                // const degrees = (currentAngle.current * 180) / Math.PI + drawAngle;
                // const sliceAngle = (arc * 180) / Math.PI;
                winIndex.current = winnerIndex.current;
            }
        }
    }
    (0, react_1.useEffect)(function () {
        var _a;
        if (loading) {
            if (!alreadyLoading.current) {
                alreadyLoading.current = true;
                easeIn.current = false;
                // speed.current = spinSpeed;
                winIndex.current = -1;
                rotateWheelLoading();
            }
        }
        else {
            alreadyLoading.current = false;
            if (canvasRef) {
                var ctx = (_a = canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current) === null || _a === void 0 ? void 0 : _a.getContext("2d");
                if (ctx) {
                    spinLoadTimeout && clearTimeout(spinLoadTimeout);
                }
            }
            spinLoading.current = false;
        }
    }, [loading]);
    function rotateWheelLoading() {
        // currentAngle.current += (speed.current * Math.PI) / 180;
        if (!easeIn.current) {
            speed.current += 0.1;
            if (speed.current >= spinSpeed) {
                speed.current = spinSpeed;
                easeIn.current = true;
            }
        }
        drawWheel();
        setSpinLoadTimeout(setTimeout(function () {
            rotateWheelLoading();
        }, 50));
    }
    function calcAngleDegrees(x, y, cx, cy) {
        return (Math.atan2(y - cy, x - cx) * 180) / Math.PI;
    }
    function rotateWheel() {
        var stopX = center.x +
            Math.sin(drawAngle * (Math.PI / 180) + Math.PI / 2) * (width / 2);
        var stopY = center.y +
            Math.cos(drawAngle * (Math.PI / 180) + Math.PI / 2) * (width / 2);
        if (!finishedAnimation.current) {
            finishedAnimation.current = true;
        }
        else if (canStop.current &&
            distance(stopX, stopY, winPCx.current, winPCy.current) <= 5) {
            stopRotateWheel();
            drawWheel();
            onSpinStop();
            return;
        }
        var factor = 0.3;
        if (remainingDegrees.current < rotationDegrees.current / 2) {
            factor = 0.2;
        }
        if (remainingDegrees.current < rotationDegrees.current / 3) {
            factor = 0.1;
        }
        if (remainingDegrees.current < rotationDegrees.current / 4) {
            factor = 0.05;
        }
        var spinDistance = factor * remainingDegrees.current;
        remainingDegrees.current -= spinDistance;
        currentAngle.current += spinDistance * (Math.PI / 180);
        if (remainingDegrees.current < 3) {
            stopRotateWheel();
            onSpinStop();
        }
        if (!easeIn.current) {
            speed.current += 0.2;
            if (speed.current >= spinSpeed) {
                speed.current = spinSpeed;
                easeIn.current = true;
            }
        }
        else if (!spinLoading.current) {
            speed.current -= 0.1;
            if (speed.current <= 2) {
                speed.current = 2;
                // canStop.current = true;
            }
        }
        drawWheel();
        setSpinTimeout(setTimeout(function () {
            rotateWheel();
        }, 50));
    }
    function spin(wIndex) {
        if (spinning.current) {
            return;
        }
        canStop.current = false;
        var angle = currentAngle.current + (wIndex + 1) * arc;
        var winX = center.x + Math.cos(angle - arc / 2) * (width / 2);
        var winY = center.y + Math.sin(angle - arc / 2) * (width / 2);
        clearInterval(idlingInterval.current);
        // speed.current = spinSpeed;
        easeIn.current = false;
        spinning.current = true;
        winIndex.current = -1;
        winnerIndex.current = wIndex !== undefined ? wIndex : -1;
        var winAngle = calcAngleDegrees(winX, winY, center.x, center.y);
        winAngle = winAngle < 0 ? 360 - -winAngle : winAngle;
        rotationDegrees.current =
            (360 - drawAngle) - winAngle + 360 * 4 + idlingDegrees.current;
        remainingDegrees.current = rotationDegrees.current;
        idlingDegrees.current = 0;
        rotateWheel();
    }
    function drawWheel() {
        var _a, _b;
        var found = false;
        if ((_a = canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current) === null || _a === void 0 ? void 0 : _a.getContext) {
            var ctx_1 = (_b = canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current) === null || _b === void 0 ? void 0 : _b.getContext("2d");
            if (ctx_1) {
                ctx_1.strokeStyle = borderColor;
                ctx_1.lineWidth = borderWidth;
                options.map(function (e, i) {
                    var _a;
                    var angle = currentAngle.current + i * arc;
                    if (!found &&
                        realPrizeIndex.current >= 0 &&
                        i - 1 === realPrizeIndex.current) {
                        found = true;
                        winPCx.current =
                            center.x + Math.cos(angle - arc / 2) * (width / 2);
                        winPCy.current =
                            center.y + Math.sin(angle - arc / 2) * (width / 2);
                    }
                    ctx_1.fillStyle =
                        i === winIndex.current ? winnerSliceColor : sliceColor;
                    ctx_1.beginPath();
                    ctx_1.moveTo(center.x, center.y);
                    ctx_1.arc(center.x, center.y, width / 2 - borderWidth, angle, angle + arc, false);
                    ctx_1.arc(center.x, center.y, insideRadius, angle + arc, angle, true);
                    ctx_1.lineTo(center.x, center.y);
                    ctx_1.stroke();
                    ctx_1.fill();
                    var stackingMargin = ((_a = e === null || e === void 0 ? void 0 : e.style) === null || _a === void 0 ? void 0 : _a[0].marginTop) || 60;
                    e.values.map(function (text, index) {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7;
                        ctx_1.save();
                        var currentStyling;
                        if (i !== winIndex.current) {
                            currentStyling = {
                                color: ((_b = (_a = e === null || e === void 0 ? void 0 : e.style) === null || _a === void 0 ? void 0 : _a[index]) === null || _b === void 0 ? void 0 : _b.color) || "black",
                                fontSize: ((_d = (_c = e === null || e === void 0 ? void 0 : e.style) === null || _c === void 0 ? void 0 : _c[index]) === null || _d === void 0 ? void 0 : _d.fontSize) || 30,
                                marginTop: ((_f = (_e = e === null || e === void 0 ? void 0 : e.style) === null || _e === void 0 ? void 0 : _e[index]) === null || _f === void 0 ? void 0 : _f.marginTop) || 0.0,
                                marginLeft: ((_h = (_g = e === null || e === void 0 ? void 0 : e.style) === null || _g === void 0 ? void 0 : _g[index]) === null || _h === void 0 ? void 0 : _h.marginLeft) || 0.0,
                                marginRight: ((_k = (_j = e === null || e === void 0 ? void 0 : e.style) === null || _j === void 0 ? void 0 : _j[index]) === null || _k === void 0 ? void 0 : _k.marginRight) || 0.0,
                                marginBottom: ((_m = (_l = e === null || e === void 0 ? void 0 : e.style) === null || _l === void 0 ? void 0 : _l[index]) === null || _m === void 0 ? void 0 : _m.marginBottom) || 0.0,
                                fontFamily: ((_p = (_o = e === null || e === void 0 ? void 0 : e.style) === null || _o === void 0 ? void 0 : _o[index]) === null || _p === void 0 ? void 0 : _p.fontFamily) || "Helvetica, Arial",
                                fontWeight: ((_r = (_q = e === null || e === void 0 ? void 0 : e.style) === null || _q === void 0 ? void 0 : _q[index]) === null || _r === void 0 ? void 0 : _r.fontWeight) || "bold",
                            };
                        }
                        else {
                            currentStyling = {
                                color: ((_t = (_s = e === null || e === void 0 ? void 0 : e.highlightStyle) === null || _s === void 0 ? void 0 : _s[index]) === null || _t === void 0 ? void 0 : _t.color) || "black",
                                fontSize: ((_v = (_u = e === null || e === void 0 ? void 0 : e.highlightStyle) === null || _u === void 0 ? void 0 : _u[index]) === null || _v === void 0 ? void 0 : _v.fontSize) || 30,
                                marginTop: ((_x = (_w = e === null || e === void 0 ? void 0 : e.highlightStyle) === null || _w === void 0 ? void 0 : _w[index]) === null || _x === void 0 ? void 0 : _x.marginTop) || 0.0,
                                marginLeft: ((_z = (_y = e === null || e === void 0 ? void 0 : e.highlightStyle) === null || _y === void 0 ? void 0 : _y[index]) === null || _z === void 0 ? void 0 : _z.marginLeft) || 0.0,
                                marginRight: ((_1 = (_0 = e === null || e === void 0 ? void 0 : e.highlightStyle) === null || _0 === void 0 ? void 0 : _0[index]) === null || _1 === void 0 ? void 0 : _1.marginRight) || 0.0,
                                marginBottom: ((_3 = (_2 = e === null || e === void 0 ? void 0 : e.highlightStyle) === null || _2 === void 0 ? void 0 : _2[index]) === null || _3 === void 0 ? void 0 : _3.marginBottom) || 0.0,
                                fontFamily: ((_5 = (_4 = e === null || e === void 0 ? void 0 : e.highlightStyle) === null || _4 === void 0 ? void 0 : _4[index]) === null || _5 === void 0 ? void 0 : _5.fontFamily) ||
                                    "Helvetica, Arial",
                                fontWeight: ((_7 = (_6 = e === null || e === void 0 ? void 0 : e.highlightStyle) === null || _6 === void 0 ? void 0 : _6[index]) === null || _7 === void 0 ? void 0 : _7.fontWeight) || "bold",
                            };
                        }
                        ctx_1.font = "".concat(currentStyling.fontWeight, " ").concat(currentStyling.fontSize, "px ").concat(currentStyling.fontFamily);
                        if (ctx_1) {
                            ctx_1.fillStyle = currentStyling.color;
                            ctx_1.translate(center.x +
                                Math.cos(angle + arc / 2 - 0.05) *
                                    ((width - currentStyling.marginTop) / 2), center.y +
                                Math.sin(angle + arc / 2 - 0.05) *
                                    ((width - currentStyling.marginTop) / 2));
                            ctx_1.rotate(angle + arc / 2 + Math.PI / 2);
                            ctx_1.fillText(text, -ctx_1.measureText(text).width / 2 +
                                currentStyling.marginLeft -
                                currentStyling.marginRight, 0);
                            ctx_1.restore();
                        }
                    });
                    if (showIndicator) {
                        // ctx.moveTo(center.x - 4, center.y - (width / 2 + 5));
                        // ctx.lineTo(center.x + 4, center.y - (width / 2 + 5));
                        // ctx.lineTo(center.x + 4, center.y - (width / 2 - 5));
                        // ctx.lineTo(center.x + 9, center.y - (width / 2 - 5));
                        // ctx.lineTo(center.x + 0, center.y - (width / 2 - 13));
                        // ctx.lineTo(center.x - 9, center.y - (width / 2 - 5));
                        // ctx.lineTo(center.x - 4, center.y - (width / 2 - 5));
                        // ctx.lineTo(center.x - 4, center.y - (width / 2 + 5));
                        ctx_1.beginPath();
                        ctx_1.strokeStyle = "orange";
                        var angle_1 = drawAngle * (Math.PI / 180);
                        ctx_1.moveTo(center.x + Math.sin(angle_1 + Math.PI / 2) * (width / 2), center.y + Math.cos(angle_1 + Math.PI / 2) * (width / 2));
                        ctx_1.lineTo(center.x + Math.sin(angle_1 + Math.PI / 2) * (width / 2.5), center.y + Math.cos(angle_1 + Math.PI / 2) * (width / 2.5));
                        ctx_1.stroke();
                        ctx_1.strokeStyle = borderColor;
                    }
                });
                if (!found && options.length > 0) {
                    winPCx.current =
                        center.x + Math.cos(currentAngle.current - arc / 2) * (width / 2);
                    winPCy.current =
                        center.y + Math.sin(currentAngle.current - arc / 2) * (width / 2);
                    // ctx.beginPath();
                    // ctx.rect(winPCx.current, winPCy.current, 10, 10);
                    // ctx.fill();
                }
            }
        }
    }
    var idle = function () {
        if (idleDirection.current) {
            if (idlingDegrees.current < 0.29) {
                var difference = 0.3 - idlingDegrees.current;
                idlingDegrees.current += 0.05 * difference;
                currentAngle.current += 0.05 * difference;
                drawWheel();
            }
            else {
                idleDirection.current = false;
            }
        }
        else {
            if (idlingDegrees.current > 0.05) {
                idlingDegrees.current -= 0.05 * idlingDegrees.current;
                currentAngle.current -= 0.05 * idlingDegrees.current;
                drawWheel();
            }
            else {
                idleDirection.current = true;
            }
        }
    };
    (0, react_1.useEffect)(function () {
        if (canvasRef) {
            drawWheel();
            if (!idlingInterval.current) {
                idlingInterval.current = setInterval(idle, 50);
            }
        }
    }, [canvasRef]);
    (0, react_1.useEffect)(function () {
        spinAction.setAction(function () { return spin; });
        return function () {
            spinTimeout && clearTimeout(spinTimeout);
        };
    }, []);
    (0, react_1.useImperativeHandle)(ref, function () { return ({
        spin: spin,
    }); });
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: rootClass, style: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                } }, { children: [(0, jsx_runtime_1.jsx)("canvas", { ref: canvasRef, width: width, height: width }), skinPath && ((0, jsx_runtime_1.jsx)("img", { style: { position: "absolute" }, className: imageFrameClass, src: skinPath, alt: "wheel frame" }))] })), children] }));
});
exports.default = Wheel;
