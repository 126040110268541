"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.lootBoxInitialState = void 0;
var ILootBox_1 = require("../../interfaces/ILootBox");
exports.lootBoxInitialState = {
    miniGame: {},
    prize: {},
    playerId: "",
    campaignId: "",
    customerId: "",
};
function lootBoxReducer(state, action) {
    if (state === void 0) { state = exports.lootBoxInitialState; }
    switch (action.type) {
        case ILootBox_1.LootBoxAction.SET_MINI_GAME: {
            return __assign(__assign({}, state), { miniGame: action.payload });
        }
        case ILootBox_1.LootBoxAction.SET_PRIZE: {
            return __assign(__assign({}, state), { prize: action.payload });
        }
        case ILootBox_1.LootBoxAction.SET_CAMPAIGN_DETAILS: {
            var _a = action.payload, playerId = _a.playerId, campaignId = _a.campaignId, customerId = _a.customerId;
            return __assign(__assign({}, state), { playerId: playerId, campaignId: campaignId, customerId: customerId });
        }
        default:
            return state;
    }
}
exports.default = lootBoxReducer;
