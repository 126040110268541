import { useLocation } from "react-router-dom";
import { useMemo } from "react";

export default function useQuery(queryName?: string) {
  const { search } = useLocation();

  return useMemo(() => {
    const urlParams = new URLSearchParams(search);
    if (queryName) {
      return urlParams.get(queryName);
    }
    return urlParams;
  }, [search, queryName]);
}
