import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  ILeaderboardsWithTheme,
  IMessage,
  IMessageContext,
  IWofWithTheme,
} from "../../interfaces/IMessage";
import { IThemeOptions, MessageTypes } from "@phantom/minigames-integration";

const Context = createContext<IMessageContext>({} as IMessageContext);

const { Provider } = Context;

const MESSAGE_SIGNAL = "message";

export const sendMessage = <P,>(message: IMessage<P>): void => {
  window.parent.postMessage(message, "*");
};

const MessagesContext: FC<PropsWithChildren<any>> = ({ children }) => {
  const [raceOptions, setRaceOptions] = useState<ILeaderboardsWithTheme>(
    {} as ILeaderboardsWithTheme
  );
  const [wofOptions, setWofOptions] = useState<IWofWithTheme>(
    {} as IWofWithTheme
  );
  const [showGame, setShowGame] = useState<boolean>(false);

  const onMessage = (ev: MessageEvent) => {
    const message: IMessage<any> = ev.data;
    console.log("===================================", message);
    switch (message.type) {
      case MessageTypes.EHUB_SHOW_COMPONENT:
        const isRace = !!(message?.payload as ILeaderboardsWithTheme)?.brandId;
        if (isRace) setRaceOptions(message?.payload as ILeaderboardsWithTheme);
        else setWofOptions(message?.payload as IWofWithTheme);
        setShowGame(true);
        sendMessage({
          type: MessageTypes.EHUB_SEND_THEME_OPTIONS,
          payload: IThemeOptions,
        });
        break;

      case MessageTypes.EHUB_START_GAME:
        sendMessage(message);
        break;
    }
  };
  const sendLocalMessage = <P,>(message: IMessage<P>): void => {
    window.postMessage(message, "*");
  };

  useEffect(() => {
    window.addEventListener(MESSAGE_SIGNAL, onMessage);
    console.log("message is put correctly");
    sendMessage({ type: MessageTypes.EHUB_LOADED });

    return () => {
      window.removeEventListener(MESSAGE_SIGNAL, onMessage);
    };
  }, []);

  return (
    <Provider
      value={{
        raceOptions,
        wofOptions,
        showGame,
        sendMessage,
        sendLocalMessage,
      }}
    >
      {children}
    </Provider>
  );
};

export const useMessages = () => useContext(Context);

export default MessagesContext;
