"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.logger = void 0;
var ILogger_1 = require("../interfaces/ILogger");
var helpers_1 = require("./helpers");
var logger = function (level) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    switch (level) {
        case ILogger_1.LogLevel.DEBUG:
            log.apply(void 0, __spreadArray([ILogger_1.LogLevel.DEBUG], args, false));
            break;
        case ILogger_1.LogLevel.ERROR:
            log.apply(void 0, __spreadArray([ILogger_1.LogLevel.ERROR], args, false));
            break;
        case ILogger_1.LogLevel.INFO:
            log.apply(void 0, __spreadArray([ILogger_1.LogLevel.INFO], args, false));
            break;
        case ILogger_1.LogLevel.SocketEvent:
            log.apply(void 0, __spreadArray([ILogger_1.LogLevel.INFO], args, false));
            break;
        case ILogger_1.LogLevel.WARN:
            log.apply(void 0, __spreadArray([ILogger_1.LogLevel.WARN], args, false));
            break;
    }
};
exports.logger = logger;
function log(logLevel) {
    var messages = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        messages[_i - 1] = arguments[_i];
    }
    var envLogLevel = (0, helpers_1.getLogLevel)();
    switch (logLevel) {
        case ILogger_1.LogLevel.DEBUG:
            if (envLogLevel !== undefined && !envLogLevel.includes(ILogger_1.LogLevel.DEBUG))
                break;
            console.debug.apply(console, messages);
            break;
        case ILogger_1.LogLevel.INFO:
            if (envLogLevel !== undefined && !envLogLevel.includes(ILogger_1.LogLevel.INFO))
                break;
            console.log.apply(console, __spreadArray(["%c == Phantom == ", "background: #f7e771; color: #17007c"], messages, false));
            break;
        case ILogger_1.LogLevel.WARN:
            if (envLogLevel !== undefined && !envLogLevel.includes(ILogger_1.LogLevel.WARN))
                break;
            console.warn.apply(console, messages);
            break;
        case ILogger_1.LogLevel.ERROR:
            if (envLogLevel !== undefined && !envLogLevel.includes(ILogger_1.LogLevel.ERROR))
                break;
            console.error.apply(console, messages);
            break;
    }
}
