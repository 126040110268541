"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var JackpotTypes_1 = require("../../interfaces/JackpotTypes");
var isInGlowTime = function (glowTime, currentTime, restartTime) { return !restartTime ? currentTime <= glowTime : false; };
var CircleProgress = function (props) {
    var circle = (0, react_1.useRef)();
    var circleSvg = (0, react_1.useRef)();
    var glowTime = isInGlowTime(props.glowTime, props.timeInSeconds, props.restartTime);
    var _a = (0, react_1.useState)(127), RADIUS = _a[0], setRadius = _a[1];
    var _b = (0, react_1.useState)(RADIUS * 2 * Math.PI), circumference = _b[0], setCircumference = _b[1];
    var setProgress = function (percent, restartTime) {
        if (restartTime) {
            return "".concat(-(circumference - percent / 100 * circumference));
        }
        return "".concat(circumference - percent / 100 * circumference);
    };
    var setSVGDimensions = function () {
        if (circleSvg.current) {
            // @ts-ignore
            var widthSvg = circleSvg.current.clientWidth, radius = widthSvg / 2 - 7;
            setRadius(radius);
            setCircumference(radius * 2 * Math.PI);
        }
    };
    (0, react_1.useEffect)(function () {
        setSVGDimensions();
        if (circle.current) {
            circle.current.style.filter = "url(#glow-".concat(props.type, ")");
        }
        window.addEventListener('resize', setSVGDimensions);
        return function () { return window.removeEventListener('resize', setSVGDimensions); };
    }, []);
    (0, react_1.useEffect)(function () {
        if (circle.current) {
            circle.current.style.strokeDashoffset = setProgress(props.percent, props.restartTime);
        }
    }, [props.percent]);
    (0, react_1.useEffect)(function () {
        if (glowTime) {
            // @ts-ignore
            circle.current.style.animation = 'pulse .6s infinite alternate';
        }
        else {
            // @ts-ignore
            circle.current.style.animation = '';
        }
    }, [glowTime]);
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ ref: circleSvg, className: "circle", xmlns: "http://www.w3.org/2000/svg" }, { children: [(0, jsx_runtime_1.jsx)("defs", { children: (0, jsx_runtime_1.jsxs)("filter", __assign({ id: "glow-".concat(props.type), filterUnits: "userSpaceOnUse", x: "-50%", y: "-50%", width: "200%", height: "200%" }, { children: [(0, jsx_runtime_1.jsx)("feGaussianBlur", { in: "SourceGraphic", stdDeviation: "3", result: "blur3" }), (0, jsx_runtime_1.jsx)("feGaussianBlur", { in: "SourceGraphic", stdDeviation: props.type === JackpotTypes_1.JackpotType.HourlyJackpot ? "10" : "8", result: "blur8" }), (0, jsx_runtime_1.jsx)("feMerge", __assign({ result: "blur-merged" }, { children: (0, jsx_runtime_1.jsx)("feMergeNode", { in: "blur8" }) })), (0, jsx_runtime_1.jsxs)("feMerge", { children: [glowTime && (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("feMergeNode", { in: "blur" }), props.type === JackpotTypes_1.JackpotType.HourlyJackpot && (0, jsx_runtime_1.jsx)("feMergeNode", { in: "blur" })] }), (0, jsx_runtime_1.jsx)("feMergeNode", { in: "blur" }), (0, jsx_runtime_1.jsx)("feMergeNode", { in: "blur3" }), (0, jsx_runtime_1.jsx)("feMergeNode", { in: "SourceGraphic" })] })] })) }), (0, jsx_runtime_1.jsx)("circle", { ref: circle, style: {
                    strokeDasharray: "".concat(circumference, " ").concat(circumference),
                    strokeDashoffset: circumference,
                    stroke: props.color
                }, cx: "50%", cy: "50%", r: RADIUS, className: "internalCircle circle-".concat(props.type) })] })));
};
exports.default = CircleProgress;
