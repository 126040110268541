"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var helpers_1 = require("../utilities/helpers");
var useTimer = function (date) {
    var _a = (0, react_1.useState)(), setTimerId = _a[1];
    var _b = (0, react_1.useState)((new Date(date).getTime() - Date.now()) / 1000), secondsLeft = _b[0], setSecondsLeft = _b[1];
    var clearTimer = function () {
        setTimerId(function (actualTimerId) {
            clearInterval(actualTimerId);
            return undefined;
        });
    };
    (0, react_1.useEffect)(function () {
        if (date) {
            clearTimer();
            setSecondsLeft((new Date(date).getTime() - Date.now()) / 1000);
            setTimerId(setInterval(function () {
                setSecondsLeft((new Date(date).getTime() - Date.now()) / 1000);
            }, 1000));
            return function () { return clearTimer(); };
        }
    }, [date]);
    (0, react_1.useEffect)(function () {
        if (secondsLeft < 0) {
            clearTimer();
        }
    }, [secondsLeft]);
    return __assign({ secondsLeft: secondsLeft }, (0, react_1.useMemo)(function () { return (0, helpers_1.getTimerDetails)(secondsLeft > 0 ? secondsLeft : 0); }, [secondsLeft]));
};
exports.default = useTimer;
