"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
require("swiper/css");
require("swiper/css/navigation");
require("swiper/css/effect-fade");
var swiper_1 = require("swiper");
var react_1 = require("swiper/react");
var SwiperComponent = function (_a) {
    var children = _a.children, slidesPerView = _a.slidesPerView, breakpoints = _a.breakpoints, _b = _a.spaceBetween, spaceBetween = _b === void 0 ? 10 : _b, _c = _a.slidesPerGroup, slidesPerGroup = _c === void 0 ? 1 : _c, _d = _a.disableMovement, disableMovement = _d === void 0 ? false : _d, setSwiper = _a.setSwiper, _e = _a.modules, modules = _e === void 0 ? [swiper_1.Navigation] : _e, _f = _a.effect, effect = _f === void 0 ? "fade" : _f;
    return ((0, jsx_runtime_1.jsx)(react_1.Swiper, __assign({ effect: effect, modules: modules, breakpoints: breakpoints, spaceBetween: spaceBetween, navigation: !disableMovement, preloadImages: true, updateOnImagesReady: true, slidesPerView: slidesPerView, slidesPerGroup: slidesPerGroup, speed: 1000, allowTouchMove: !disableMovement, onSwiper: setSwiper }, { children: children })));
};
SwiperComponent.displayName = "SwiperComponent";
exports.default = SwiperComponent;
