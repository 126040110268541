"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.languages = exports.localeSubpaths = exports.defaultLanguage = exports.namespaces = void 0;
var ILogger_1 = require("../interfaces/ILogger");
var logger_1 = require("./logger");
exports.namespaces = ["wof"];
exports.defaultLanguage = "en";
var translations = {};
exports.localeSubpaths = {
    en: "en",
    ro: "ro",
};
exports.languages = Object.keys(exports.localeSubpaths).map(function (language) { return language.toLowerCase(); });
exports.languages.forEach(function (language) {
    translations[language] = {};
    exports.namespaces.forEach(function (namespace) {
        try {
            translations[language][namespace] = require("../locales/".concat(language, "/").concat(namespace, ".json"));
        }
        catch (e) {
            (0, logger_1.logger)(ILogger_1.LogLevel.ERROR, "!!!", e);
            (0, logger_1.logger)(ILogger_1.LogLevel.INFO, "Check ".concat(language, "/").concat(namespace, ".json"));
        }
    });
});
exports.default = translations;
