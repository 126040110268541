import "./styles.scss";
import { FC, useEffect, useState } from "react";
import { IMiniGameUpdateReward } from "@phantom/ehub-components";
import { useMessages } from "../../contexts/messages/context";
import { MessageTypes } from "@phantom/minigames-integration";
import congratsIcon from "./images/icon_congrats.png";

interface IProps {
  prize: IMiniGameUpdateReward;
}

const MegapuestaWinModal: FC<IProps> = ({ prize }) => {
  const { sendMessage } = useMessages();
  const [disabled, setDisabled] = useState(false);

  return (
    <div className={"modal"}>
      <div className="opaque"></div>
      <div className={"modal-wrapper"}>
        <div className={"content"}>
          {prize.title && prize.title !== "No Win" && (
            <>
              <img src={congratsIcon} className={"congrats-icon"} />
              <h2 className={"title"}>Felicitaciones!</h2>
              <span className={"separator"}></span>
              <p className={"body"}>
                Tienes<br></br>
                <span className={"yellow"}>{prize.title}</span>
              </p>
              <button
                disabled={disabled}
                onClick={() => {
                  sendMessage({ type: MessageTypes.EHUB_REDIRECT });
                  setDisabled(true);
                }}
                className={"button"}
              >
                JUEGA GRATIS Y GANA
              </button>
            </>
          )}
          {prize.title && prize.title === "No Win" && (
            <>
              <h2>¡Trata de nuevo mañana!</h2>
              <button
                disabled={disabled}
                onClick={() => {
                  sendMessage({ type: "REDIRECT_HOME" });
                  setDisabled(true);
                }}
                className={"button"}
              >
                Atrás
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default MegapuestaWinModal;
