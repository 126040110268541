"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCampaignDetails = exports.setLootBoxPrize = exports.setActiveLootBox = void 0;
var ILootBox_1 = require("../../interfaces/ILootBox");
var setActiveLootBox = function (miniGame) { return ({
    type: ILootBox_1.LootBoxAction.SET_MINI_GAME,
    payload: miniGame,
}); };
exports.setActiveLootBox = setActiveLootBox;
var setLootBoxPrize = function (prize) { return ({
    type: ILootBox_1.LootBoxAction.SET_PRIZE,
    payload: prize,
}); };
exports.setLootBoxPrize = setLootBoxPrize;
var setCampaignDetails = function (playerId, campaignId, customerId) { return ({
    type: ILootBox_1.LootBoxAction.SET_CAMPAIGN_DETAILS,
    payload: { playerId: playerId, campaignId: campaignId, customerId: customerId },
}); };
exports.setCampaignDetails = setCampaignDetails;
