"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.winnersWidgetConfig = exports.defaultConfig = void 0;
exports.defaultConfig = {
    appendCurrencyToLeft: false,
    decimalPartAppendType: 2,
    decimalPartDigitsCount: 2,
    decimalPartSeparatorCharacter: '.',
    realPartSeparatorCharacter: ',',
    realPartSeparationDigitsCount: 2,
    shortMode: true,
    showCurrency: true,
    tickupDecimalsAsLineBet: true,
};
exports.winnersWidgetConfig = {
    appendCurrencyToLeft: false,
    decimalPartAppendType: 2,
    decimalPartDigitsCount: 0,
    decimalPartSeparatorCharacter: ',',
    realPartSeparatorCharacter: '.',
    realPartSeparationDigitsCount: 2,
    shortMode: false,
    showCurrency: true,
    tickupDecimalsAsLineBet: true,
};
