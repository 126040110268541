"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var TICKER_INTERVAL = 1000;
var checkIsStarted = function (ts) { return ts <= Date.now(); };
exports.default = (function (startTime) {
    var _a = (0, react_1.useState)(checkIsStarted(startTime)), isStarted = _a[0], setIsStarted = _a[1];
    (0, react_1.useEffect)(function () {
        var id = setInterval(function () {
            var newState = checkIsStarted(startTime);
            if (newState !== isStarted) {
                setIsStarted(newState);
            }
        }, TICKER_INTERVAL);
        return function () { return clearInterval(id); };
    }, [startTime, isStarted]);
    return isStarted;
});
