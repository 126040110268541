"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMoneyFormatter = void 0;
var sw_money_formatter_1 = require("@skywind-group/sw-money-formatter");
var moneyFormatterConfigs_1 = require("../utilities/moneyFormatterConfigs");
var useMoneyFormatter = function () {
    var moneyFormatter = new sw_money_formatter_1.MoneyFormatter();
    moneyFormatter.config = moneyFormatterConfigs_1.defaultConfig;
    var moneyFormat = function (value, currency, config) {
        if (value === void 0) { value = 0; }
        moneyFormatter.currencySymbol = currency;
        if (config) {
            moneyFormatter.config = config;
        }
        if (moneyFormatter && value === +value && moneyFormatter.config) {
            return moneyFormatter.formatNumber(value, moneyFormatter.config);
        }
    };
    var getCurrencySymbol = function (currency) {
        if (currency) {
            moneyFormatter.currencySymbol = currency;
            return moneyFormatter.currencySymbol;
        }
        return "";
    };
    return { moneyFormat: moneyFormat, getCurrencySymbol: getCurrencySymbol };
};
exports.useMoneyFormatter = useMoneyFormatter;
