"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setShortStats = exports.setLastWinners = exports.setGames = void 0;
var IRubyContext_1 = require("../../interfaces/IRubyContext");
var setGames = function (games) { return ({
    type: IRubyContext_1.IRubyJackpotActions.SET_GAMES,
    payload: games,
}); };
exports.setGames = setGames;
var setLastWinners = function (lastWinners) { return ({
    type: IRubyContext_1.IRubyJackpotActions.SET_LATEST_WINNERS,
    payload: lastWinners,
}); };
exports.setLastWinners = setLastWinners;
var setShortStats = function (shortStats) { return ({
    type: IRubyContext_1.IRubyJackpotActions.SET_SHORT_STATISTICS,
    payload: shortStats,
}); };
exports.setShortStats = setShortStats;
