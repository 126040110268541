"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Orientations = exports.Events = exports.ReqType = void 0;
var ReqType;
(function (ReqType) {
    ReqType["Get"] = "GET";
    ReqType["Post"] = "POST";
    ReqType["Patch"] = "PATCH";
    ReqType["Put"] = "PUT";
})(ReqType = exports.ReqType || (exports.ReqType = {}));
var Events;
(function (Events) {
    Events["SCROLL_TO_YOU"] = "SCROLL_TO_YOU";
})(Events = exports.Events || (exports.Events = {}));
var Orientations;
(function (Orientations) {
    Orientations["PORTRAIT"] = "Portrait";
    Orientations["LANDSCAPE"] = "Landscape";
})(Orientations = exports.Orientations || (exports.Orientations = {}));
