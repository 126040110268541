"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDispatch = exports.useCampaignDetails = exports.useLootBoxPrize = exports.useActiveLootBox = void 0;
var context_1 = require("./context");
var useActiveLootBox = function () {
    var state = (0, context_1.useLootBox)().state;
    return state.miniGame;
};
exports.useActiveLootBox = useActiveLootBox;
var useLootBoxPrize = function () {
    var state = (0, context_1.useLootBox)().state;
    return state.prize;
};
exports.useLootBoxPrize = useLootBoxPrize;
var useCampaignDetails = function () {
    var state = (0, context_1.useLootBox)().state;
    var playerId = state.playerId, campaignId = state.campaignId, customerId = state.customerId;
    return { playerId: playerId, campaignId: campaignId, customerId: customerId };
};
exports.useCampaignDetails = useCampaignDetails;
var useDispatch = function () {
    var dispatch = (0, context_1.useLootBox)().dispatch;
    return dispatch;
};
exports.useDispatch = useDispatch;
