"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAppConfig = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_intl_1 = require("react-intl");
var helpers_1 = require("../../utilities/helpers");
var translations_1 = __importStar(require("../../utilities/translations"));
var AppConfigContext = (0, react_1.createContext)({});
var AppConfigProvider = function (_a) {
    var children = _a.children, config = _a.config;
    return ((0, jsx_runtime_1.jsx)(AppConfigContext.Provider, __assign({ value: config }, { children: (0, jsx_runtime_1.jsx)(react_intl_1.IntlProvider, __assign({ locale: config.language, defaultLocale: translations_1.defaultLanguage, messages: (0, helpers_1.flattenMessages)(translations_1.default[config.language]) }, { children: children })) })));
};
exports.default = AppConfigProvider;
var useAppConfig = function () { return (0, react_1.useContext)(AppConfigContext); };
exports.useAppConfig = useAppConfig;
