"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appendCss = void 0;
var appendCss = function (value) {
    if (!value) {
        return;
    }
    var styleTag = document.createElement("style");
    document.head.appendChild(styleTag);
    styleTag.appendChild(document.createTextNode(value));
};
exports.appendCss = appendCss;
