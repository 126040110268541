"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var time_1 = require("../../../../utils/time");
var clamp = function (val) { return val < 0 ? 0 : val; };
var pad = function (n) { return "".concat(n).padStart(2, '0'); };
var floor = Math.floor;
var format = function (seconds) {
    var h = floor(seconds / time_1.HOUR_S);
    var m = floor(seconds / time_1.MINUTE_S) % time_1.HOUR_M;
    var s = seconds % time_1.MINUTE_S;
    return "".concat(h ? "".concat(pad(h), ":") : '').concat(pad(m), ":").concat(pad(s));
};
var getSecondsTo = function (timestamp) { return floor((timestamp - Date.now()) / time_1.SECOND_MS); };
exports.default = (function (timestamp) {
    var _a = (0, react_1.useState)(getSecondsTo(timestamp)), seconds = _a[0], setSeconds = _a[1];
    var decrement = function () { return setSeconds(function (prev) { return clamp(prev - 1); }); };
    (0, react_1.useEffect)(function () { return setSeconds(getSecondsTo(timestamp)); }, [timestamp]);
    (0, react_1.useEffect)(function () {
        var id = setInterval(decrement, time_1.SECOND_MS);
        return function () { return clearInterval(id); };
    }, [seconds]);
    return { time: format(seconds), completed: seconds === 0, timeInSeconds: seconds };
});
