"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DispatchEvents = exports.FeatureSize = exports.AssetsTypes = void 0;
var AssetsTypes;
(function (AssetsTypes) {
    AssetsTypes["CSS"] = "css";
    AssetsTypes["TICKET_BACKGROUND_COLOR"] = "tickerBackgroundColor";
    AssetsTypes["LOGO"] = "logo";
    AssetsTypes["DISPLAY_DESCRIPTION"] = "displayDescription";
    AssetsTypes["DISPLAY_NAME"] = "displayName";
    AssetsTypes["WIDGET_GAMES_SLIDER"] = "widget-games-slider";
    AssetsTypes["WIDGET_CSS"] = "widget-css";
    AssetsTypes["HEAT_ANIMATION_COLOR"] = "heatAnimationColor";
})(AssetsTypes = exports.AssetsTypes || (exports.AssetsTypes = {}));
var FeatureSize;
(function (FeatureSize) {
    FeatureSize["LARGE"] = "LARGE";
    FeatureSize["SMALL"] = "SMALL";
})(FeatureSize = exports.FeatureSize || (exports.FeatureSize = {}));
var DispatchEvents;
(function (DispatchEvents) {
    DispatchEvents["SetState"] = "SetState";
    DispatchEvents["SetFeatures"] = "SetFeatures";
    DispatchEvents["SetAssets"] = "SetAssets";
    DispatchEvents["Reset"] = "Reset";
})(DispatchEvents = exports.DispatchEvents || (exports.DispatchEvents = {}));
