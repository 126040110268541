"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MiniGamesRewardTypes = exports.MiniGameErrors = exports.MiniGamesTypes = void 0;
;
var MiniGamesTypes;
(function (MiniGamesTypes) {
    MiniGamesTypes["Wof"] = "wheelOfFortune";
    MiniGamesTypes["LootBox"] = "lootBox";
    MiniGamesTypes["Doors"] = "doors";
})(MiniGamesTypes = exports.MiniGamesTypes || (exports.MiniGamesTypes = {}));
var MiniGameErrors;
(function (MiniGameErrors) {
    MiniGameErrors["BadRequestError"] = "BadRequestError";
})(MiniGameErrors = exports.MiniGameErrors || (exports.MiniGameErrors = {}));
var MiniGamesRewardTypes;
(function (MiniGamesRewardTypes) {
    MiniGamesRewardTypes["Bonus"] = "bonus";
    MiniGamesRewardTypes["FreeSpins"] = "freeSpins";
    MiniGamesRewardTypes["FreeBets"] = "freeBets";
})(MiniGamesRewardTypes = exports.MiniGamesRewardTypes || (exports.MiniGamesRewardTypes = {}));
