"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePoolStatistic = exports.useLatestWinners = exports.useGames = void 0;
var JackpotTypes_1 = require("../../components/rubyJackpot/App/interfaces/JackpotTypes");
var context_1 = require("./context");
var useGames = function () {
    var state = (0, context_1.useRubyJackpotContext)().state;
    return state.games;
};
exports.useGames = useGames;
var useLatestWinners = function () {
    var state = (0, context_1.useRubyJackpotContext)().state;
    return state.lastWinners;
};
exports.useLatestWinners = useLatestWinners;
var usePoolStatistic = function (poolType) {
    var _a;
    var state = (0, context_1.useRubyJackpotContext)().state;
    var shortStatisticsInfo = state.shortStatisticsInfo;
    var type;
    switch (poolType) {
        case JackpotTypes_1.JackpotType.DailyJackpot:
            type = JackpotTypes_1.JackpotTypes.DAILY;
            break;
        case JackpotTypes_1.JackpotType.HourlyJackpot:
            type = JackpotTypes_1.JackpotTypes.HOURLY;
    }
    var poolStatistic = (_a = shortStatisticsInfo.pools) === null || _a === void 0 ? void 0 : _a.find(function (infoPool) { return infoPool.poolInfo.poolType === type; });
    if (poolStatistic) {
        return poolStatistic;
    }
    return {};
};
exports.usePoolStatistic = usePoolStatistic;
