"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.internalPostMessage = exports.createMessage = void 0;
var ILogger_1 = require("../interfaces/ILogger");
var logger_1 = require("./logger");
var createMessage = function (type, payload) { return ({
    type: type,
    payload: payload,
}); };
exports.createMessage = createMessage;
var internalPostMessage = function (value) {
    window.postMessage((0, exports.createMessage)(value.type, value.payload), "*");
    (0, logger_1.logger)(ILogger_1.LogLevel.INFO, "POST", value);
};
exports.internalPostMessage = internalPostMessage;
