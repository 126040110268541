"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.queriesToString = void 0;
var appendStartOfQueryParam = function (append, value) {
    if (append) {
        return "?".concat(value);
    }
    return value;
};
var queriesToString = function (queries) {
    if (!queries) {
        return "";
    }
    var firstParam = false;
    return Object.entries(queries)
        .reduce(function (queries, query) {
        var key = query[0], queryValue = query[1];
        if (queries.length === 0) {
            firstParam = true;
        }
        else {
            firstParam = false;
        }
        if (queryValue) {
            if (Array.isArray(queryValue)) {
                return __spreadArray(__spreadArray([], queries, true), [appendStartOfQueryParam(firstParam, "".concat(key, "=").concat(queryValue.join(",")))], false);
            }
            return __spreadArray(__spreadArray([], queries, true), [appendStartOfQueryParam(firstParam, "".concat(key, "=").concat(queryValue))], false);
        }
        return queries;
    }, [])
        .join("&");
};
exports.queriesToString = queriesToString;
