"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDictionary = exports.createDictionary = exports.getI18nDict = void 0;
var i18n_1 = require("@phantom/ph-client-common/i18n");
var dictionaryI18n;
var getI18nDict = function (lang) {
    if (lang === void 0) { lang = "EN" /* Language.EN */; }
    return require("./translations/".concat(lang.toLowerCase(), ".json"));
};
exports.getI18nDict = getI18nDict;
var createDictionary = function (lang) {
    dictionaryI18n = (0, i18n_1.createI18n)((0, exports.getI18nDict)(lang) || (0, exports.getI18nDict)("EN" /* Language.EN */));
};
exports.createDictionary = createDictionary;
var getDictionary = function () { return dictionaryI18n; };
exports.getDictionary = getDictionary;
