"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogColor = exports.LogLevel = void 0;
var LogLevel;
(function (LogLevel) {
    LogLevel["ERROR"] = "error";
    LogLevel["WARN"] = "warn";
    LogLevel["INFO"] = "info";
    LogLevel["DEBUG"] = "debug";
    LogLevel["SocketEvent"] = "socketEvent";
})(LogLevel = exports.LogLevel || (exports.LogLevel = {}));
var LogColor;
(function (LogColor) {
    LogColor["DEFAULT"] = "default";
    LogColor["GREEN"] = "green";
    LogColor["RED"] = "red";
    LogColor["YELLOW"] = "yellow";
    LogColor["BLUE"] = "blue";
})(LogColor = exports.LogColor || (exports.LogColor = {}));
