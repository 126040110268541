import { ReactElement, useState, useEffect } from "react";
import "./styles.scss";
import { useMessages } from "../../contexts/messages/context";
import RuletaLogo from "./images/logo_wof_desktop.png";

interface IProps {
  playerId: string | undefined;
  campaignID: string | undefined;
}

const NotAvailableModal = ({ playerId, campaignID }: IProps): ReactElement => {
  const { sendMessage } = useMessages();
  const [disabled, setDisabled] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isMinigameAvailable, setIsMinigameAvailable] = useState(false);

  useEffect(() => {
    if (playerId && playerId !== "") {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }

    if (campaignID && campaignID !== "") {
      setIsMinigameAvailable(true);
    } else {
      setIsMinigameAvailable(false);
    }
  }, [playerId, campaignID]);

  return (
    <div className={"error-modal"}>
      <div className={"error-body"}>
        <img src={RuletaLogo} className={"roulette-logo"} />
        <h2 className={"title"}>
          {!isAuth && !isMinigameAvailable && "Ooops!"}
          {!isAuth && isMinigameAvailable && "Ooops!"}
          {isAuth && !isMinigameAvailable && "No campaign available!"}
        </h2>
        <p className={"description"}>
          {!isAuth && !isMinigameAvailable && "You need to log in!"}
          {!isAuth && isMinigameAvailable && "You need to log in!"}
          {isAuth &&
            !isMinigameAvailable &&
            "Please return tommorow for a new chance"}
        </p>
        <button
          disabled={disabled}
          onClick={() => {
            sendMessage({ type: "REDIRECT_HOME" });
            setDisabled(true);
          }}
          className={"button"}
        >
          Espalda
        </button>
      </div>
    </div>
  );
};

export default NotAvailableModal;
