"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JackpotTypes = exports.JackpotType = void 0;
var JackpotType;
(function (JackpotType) {
    JackpotType["NoLimit"] = "NoLimit";
    JackpotType["AmountLimit"] = "AmountLimit";
    JackpotType["HourlyJackpot"] = "HourlyJackpot";
    JackpotType["DailyJackpot"] = "DailyJackpot";
})(JackpotType = exports.JackpotType || (exports.JackpotType = {}));
var JackpotTypes;
(function (JackpotTypes) {
    JackpotTypes["DAILY"] = "daily";
    JackpotTypes["HOURLY"] = "hourly";
    JackpotTypes["GRAND"] = "grand";
    JackpotTypes["AMOUNT"] = "amount";
})(JackpotTypes = exports.JackpotTypes || (exports.JackpotTypes = {}));
