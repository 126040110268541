"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var Loader = function (_a) {
    var _b = _a.show, show = _b === void 0 ? false : _b;
    if (!show) {
        return null;
    }
    return (0, jsx_runtime_1.jsx)("div", { className: "loader" });
};
exports.default = Loader;
