"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAssetValueByKey = exports.getAssetByKey = void 0;
var getAssetByKey = function (assets, key) {
    return assets.find(function (item) { return item.type === key; });
};
exports.getAssetByKey = getAssetByKey;
var getAssetValueByKey = function (assets, key) {
    var asset = (0, exports.getAssetByKey)(assets, key);
    return asset === null || asset === void 0 ? void 0 : asset.value;
};
exports.getAssetValueByKey = getAssetValueByKey;
