"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.rubyJackpotInitialState = void 0;
var IRubyContext_1 = require("../../interfaces/IRubyContext");
exports.rubyJackpotInitialState = {
    games: [],
    lastWinners: [],
    shortStatisticsInfo: {},
};
function rubyJackpotReducer(state, action) {
    if (state === void 0) { state = exports.rubyJackpotInitialState; }
    switch (action.type) {
        case IRubyContext_1.IRubyJackpotActions.SET_GAMES:
            return __assign(__assign({}, state), { games: action.payload || [] });
        case IRubyContext_1.IRubyJackpotActions.SET_LATEST_WINNERS:
            return __assign(__assign({}, state), { lastWinners: action.payload || [] });
        case IRubyContext_1.IRubyJackpotActions.SET_SHORT_STATISTICS:
            return __assign(__assign({}, state), { shortStatisticsInfo: action.payload });
        default:
            return state;
    }
}
exports.default = rubyJackpotReducer;
