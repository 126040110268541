import { ReactElement, useState } from "react";
import "./styles.scss";
import { useMessages } from "../../contexts/messages/context";
import RuletaLogo from "./images/logo_wof_desktop.png";
import clsx from "clsx";

const Spin247Error = (): ReactElement => {
  const { sendMessage } = useMessages();
  const [disabled, setDisabled] = useState(false);

  return (
    <div className={"error-modal"}>
      <div className={"error-body"}>
        <img src={RuletaLogo} className={"roulette-logo"} />
        <h2 className={clsx("title", "errorTitle")}>Ooops!</h2>
        <p className={"description"}>Please try again later.</p>
        <button
          disabled={disabled}
          onClick={() => {
            sendMessage({ type: "REDIRECT_REFRESH" });
            setDisabled(true);
          }}
          className={clsx("button", "errorButton")}
        >
          Retry
        </button>
      </div>
    </div>
  );
};

export default Spin247Error;
