"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFormattedTime = exports.formatDate = exports.minutesToTimestamp = exports.HOUR_S = exports.MINUTE_MS = exports.HOUR_M = exports.MINUTE_S = exports.SECOND_MS = void 0;
exports.SECOND_MS = 1000;
exports.MINUTE_S = 60;
exports.HOUR_M = exports.MINUTE_S;
exports.MINUTE_MS = exports.MINUTE_S * exports.SECOND_MS;
exports.HOUR_S = exports.HOUR_M * exports.MINUTE_S;
var minutesToTimestamp = function (minutes) {
    return Date.now() + minutes * exports.MINUTE_MS;
};
exports.minutesToTimestamp = minutesToTimestamp;
var formatDate = function (timestamp) {
    var date = new Date(timestamp);
    var options = {
        hour: 'numeric',
        hour12: true,
    };
    if (date.getMinutes() !== 0) {
        options = __assign(__assign({}, options), { minute: 'numeric', hour12: false });
    }
    return date.toLocaleString('en-US', options);
};
exports.formatDate = formatDate;
var getFormattedTime = function (minutes) {
    return (0, exports.formatDate)((0, exports.minutesToTimestamp)(minutes));
};
exports.getFormattedTime = getFormattedTime;
