"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var gamesMap = new Map([
    ["sw_azre", { codeName: "SWAztecRespin", displayName: "Aztec Respin" }],
    ["sw_bul", { codeName: "SKWBuffaloLightning", displayName: "Big Buffalo Lightning" }],
    ["sw_chwi", { codeName: "SKWCheshireWild", displayName: "Cheshire Wild" }],
    ["sw_gg", { codeName: "SKWGoGold", displayName: "Go Gold" }],
    ["sw_js", { codeName: "SKWJuicyStacks", displayName: "Juicy Stacks" }],
    ["sw_cf", { codeName: "SKWChilliFestival", displayName: "Chilli Festival" }],
    ["sw_dd", { codeName: "SKWDolphinDelight", displayName: "Dolphin Delight" }],
    ["sw_ex", { codeName: "SKWExplosion", displayName: "Explosion" }],
    ["sw_gq", { codeName: "SWGemQueen", displayName: "Gem Queen" }],
    ["sw_kiwi", { codeName: "SKWKittyWild", displayName: "Kitty Wild" }],
    ["sw_mm", { codeName: "SKWMayaMillions", displayName: "Maya Millions" }],
    ["sw_cada", { codeName: "SWReadySetCASH", displayName: "Ready Set Cash" }],
    ["sw_bb", { codeName: "SKWBigBuffalo", displayName: "Big Buffalo" }],
    ["sw_csi", { codeName: "SKWCSI", displayName: "CSI: Crime Scene Investigation" }],
    ["sw_wi0", { codeName: "SWWicked777", displayName: "Wicked 777" }],
    ["sw_wifl", { codeName: "SWWickedFlamingo", displayName: "Wicked Flamingo" }],
    ["sw_cb", { codeName: "SWCaptainBoom", displayName: "Captain Boom" }],
    ["sw_dc", { codeName: "SKWDoubleChilli", displayName: "Double Chilli" }],
    ["sw_es", { codeName: "SKWEgyptSpin", displayName: "Egypt Spin" }],
    ["sw_mopa", { codeName: "", displayName: "" }],
    ["sw_gtg", { codeName: "", displayName: "" }],
    ["sw_ksm", { codeName: "SKWKingSolomonMines", displayName: "King Solomons Mines" }],
    ["sw_reev", { codeName: "SKWResidentEvil", displayName: "Resident Evil 6" }],
    ["sw_remamere", { codeName: "SKWRespinMania", displayName: "Respin Mania Mega Reels" }],
    ["sw_vi", { codeName: "SKWVolcanoIsland", displayName: "Volcano Island" }],
    ["sw_wf", { codeName: "SKWWildFive", displayName: "Wild Five" }],
    ["sw_ra", { codeName: "SWRainBalls", displayName: "Rain Balls" }],
    ["sw_rm", { codeName: "SKWRespinMania", displayName: "Respin Mania" }],
    ["sw_wifr", { codeName: "SWWickedHot", displayName: "Wicked Hot" }],
    ["sw_bd", { codeName: "SKWBonusDigger", displayName: "Bonus Digger" }],
    ["sw_prli", { codeName: "SWJaguarGold", displayName: "Jaguar Gold" }],
    ["sw_qow", { codeName: "", displayName: "" }],
    ["sw_bibume_955", { codeName: "", displayName: "" }],
]);
exports.default = gamesMap;
