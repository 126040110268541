import { Navigate, Route, Routes } from "react-router-dom";
import React, { ReactElement } from "react";
import { AppConfigProvider } from "@phantom/ehub-components";
import config from "./utils/helpers";
import MessagesContext from "./contexts/messages/context";
import Main from "./Main";

const App = (): ReactElement => {
  return (
    <AppConfigProvider config={config(process.env)}>
      <MessagesContext>
        <Main />
      </MessagesContext>
    </AppConfigProvider>
  );
};

export default App;
