import "@phantom/ehub-components/styles/wheelOfFortune.css";
import React, { ReactElement } from "react";
import { WheelOfFortune } from "@phantom/ehub-components";
import { useMessages } from "../contexts/messages/context";
import { IThemeOptions } from "@phantom/minigames-integration";
import { WheelMegapuesta } from "../themes/megapuesta/WheelMegapuesta";
import { WheelSpin247 } from "../themes/spin247/WheelSpin247";

export const WheelPage = (): ReactElement => {
  const {
    wofOptions: { theme, ...wofOptions },
    showGame,
  } = useMessages();

  const renderWheel = () => {
    switch (theme) {
      case IThemeOptions.MEGAPUESTA:
        return <WheelMegapuesta options={wofOptions} />;
      case IThemeOptions.SPIN247:
        return <WheelSpin247 options={wofOptions} />;
      default:
        return <WheelOfFortune options={wofOptions} />;
    }
  };
  return <>{showGame && renderWheel()}</>;
};
