"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getEnvParam = function (param) {
    try {
        return process && process.env[param];
    }
    catch (e) {
        return undefined;
    }
};
var config = function () { return ({
    tournamentApiUrl: getEnvParam("NEXT_PUBLIC_TOURNAMENT_API"),
    winnersWidgetApiUrl: getEnvParam("NEXT_PUBLIC_WINNERS_WIDGET_API"),
    rubyApiUrl: getEnvParam("NEXT_PUBLIC_RUBY_API"),
    rubyCdnDomain: getEnvParam("NEXT_PUBLIC_RUBY_CDN"),
    rubyMainDomain: getEnvParam("NEXT_PUBLIC_RUBY_MAIN_DOMAIN"),
    miniGamesApi: getEnvParam("NEXT_PUBLIC_MINIGAMES_API"),
    logLevel: getEnvParam("NEXT_PUBLIC_LOG_LEVEL"),
    language: "en",
}); };
exports.default = config;
