"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var ImageSequence = function (_a) {
    var sequence = _a.sequence, progress = _a.progress, onMouseEnter = _a.onMouseEnter, onMouseLeave = _a.onMouseLeave, onClick = _a.onClick, children = _a.children;
    var images = sequence.images;
    var activeSlide = (0, react_1.useRef)(0);
    var _b = (0, react_1.useState)(activeSlide.current), renderSlide = _b[0], setRenderSlide = _b[1];
    var ref = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        var slice = 100 / (images.length - 1);
        var slide = Math.floor(progress / slice);
        setRenderSlide(slide);
        activeSlide.current = slide;
    }, [progress]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ onClick: onClick, onMouseLeave: onMouseLeave, onMouseEnter: onMouseEnter, className: "lootbox--sequence", ref: ref }, { children: [images.map(function (image, index) {
                var isActive = renderSlide === index;
                return ((0, jsx_runtime_1.jsx)("img", { style: { display: isActive ? "block" : "none" }, src: image }, "".concat(image.slice(0, 3), "-").concat(index)));
            }), children] })));
};
exports.default = ImageSequence;
