import "./styles.scss";
import { IWofOptions, WheelOfFortune } from "@phantom/ehub-components";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import MegapuestaLogo from "./images/logo_megapuesta.png";
import RuletaLogo from "./images/logo_ruleta.png";
import MegapuestaError from "./MegapuestaError";
import MegapuestaWinModal from "./MegapuestaWinModal";
import NotAvailableModal from "./NotAvailableModal";
import { checkIsMobile } from "../../utils/helpers";
import { LoadingAnimation } from "./Loading/LoadingAnimation";

interface IProps {
  options: IWofOptions;
}

export const WheelMegapuesta = ({ options }: IProps): ReactElement => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [renderCondition, setRenderCondition] = useState(false);
  const [resizeTimeStamp, setResizeTimeStamp] = useState<Date | null>(null);
  const [showWheel, setShowWheel] = useState<boolean>(true);

  const handleResize = (): void => {
    setResizeTimeStamp(new Date());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (resizeTimeStamp) {
      setShowWheel(false);

      setTimeout(() => {
        const now = new Date();
        if (now.getTime() - resizeTimeStamp.getTime() > 300) {
          setShowWheel(true);
        }
      }, 1000);
    }
  }, [resizeTimeStamp]);

  const { width, insideRadius, style, highlightStyle } = useMemo(() => {
    const isLandscape = window?.innerWidth > window?.innerHeight;
    const isMobile = checkIsMobile();

    let height = window.innerHeight;
    let width = window.innerWidth;

    if (isMobile) {
      if (isLandscape) {
        height = window.innerWidth;
        width = window.innerHeight;
      }
    }

    return {
      width: width > 800 ? height * 0.4 : width * 0.8,
      insideRadius: width > 800 ? height * 0.02 : 20,
      style: [
        {
          color: "#f1cf00",
          fontWeight: "bold",
          fontSize: 20,
          fontFamily: "Helvetica, Arial",
          marginTop: 40,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        },
        {
          color: "#ffffff",
          fontWeight: "bold",
          fontSize: 20,
          fontFamily: "Helvetica, Arial",
          marginTop: -80,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        },
        {
          color: "#ffffff",
          fontWeight: "bold",
          fontSize: 20,
          fontFamily: "Helvetica, Arial",
          marginTop: -175,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        },
        {
          color: "#ffffff",
          fontWeight: "bold",
          fontSize: 20,
          fontFamily: "Helvetica, Arial",
          marginTop: -175,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        },
      ],
      highlightStyle: [
        {
          color: "#000",
          fontWeight: "bold",
          fontSize: 20,
          fontFamily: "Helvetica, Arial",
          marginTop: 40,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        },
        {
          color: "#000",
          fontWeight: "bold",
          fontSize: 20,
          fontFamily: "Helvetica, Arial",
          marginTop: -80,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        },
        {
          color: "#000",
          fontWeight: "bold",
          fontSize: 20,
          fontFamily: "Helvetica, Arial",
          marginTop: -175,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        },
        {
          color: "#000",
          fontWeight: "bold",
          fontSize: 20,
          fontFamily: "Helvetica, Arial",
          marginTop: -175,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        },
      ],
    };
  }, [window.innerWidth, showWheel]);

  useEffect(() => {
    if (showWheel) {
      let styleFontSize =
        window.innerWidth > 800 ? width * 0.0465 : width * 0.05;
      let marginTopTextOne =
        window.innerWidth > 800 ? width * 0.3 : width * 0.33;
      let marginTopTextTwo =
        window.innerWidth > 800 ? width * 0.4 : width * 0.43;
      let marginTopTextThree =
        window.innerWidth > 800 ? width * 0.5 : width * 0.53;
      let marginTopTextFour =
        window.innerWidth > 800 ? width * 0.6 : width * 0.6;

      style.map((item, index) => {
        item.fontSize = styleFontSize;

        if (index === 0) {
          item.marginTop = marginTopTextOne;
        }

        if (index === 1) {
          item.marginTop = marginTopTextTwo;
        }

        if (index === 2) {
          item.marginTop = marginTopTextThree;
        }

        if (index === 3) {
          item.marginTop = marginTopTextFour;
          item.fontSize =
            window.innerWidth > 800 ? width * 0.04 : width * 0.043;
        }
      });

      highlightStyle.map((item, index) => {
        item.fontSize = styleFontSize;

        if (index === 0) {
          item.marginTop = marginTopTextOne;
        }

        if (index === 1) {
          item.marginTop = marginTopTextTwo;
        }

        if (index === 2) {
          item.marginTop = marginTopTextThree;
        }

        if (index === 3) {
          item.marginTop = marginTopTextFour;
          item.fontSize =
            window.innerWidth > 800 ? width * 0.04 : width * 0.043;
        }
      });
    }
  }, [highlightStyle, style, width, showWheel]);

  useEffect(() => {
    setRenderCondition(
      !!options.playerId && !!options.gameId && !!options.campaignId
    );
  }, [options.gameId, options.playerId, options.campaignId]);

  return (
    <div className={"wrapper"}>
      <div className={"content"}>
        <img src={RuletaLogo} className={"roulette-logo"} />
        <img src={MegapuestaLogo} className={"megapuesta-logo"} />

        {renderCondition ? (
          <>
            {!showWheel ? (
              <LoadingAnimation />
            ) : (
              <WheelOfFortune
                onError={() => <MegapuestaError />}
                renderHeader={undefined}
                options={options}
                renderOnlyWheel={true}
                wheelInModal={false}
                winModalContent={(ref, prize, onClose) => (
                  <MegapuestaWinModal prize={prize} />
                )}
                sliceSettings={{
                  sliceColor: "#101020",
                  highlightStyle: highlightStyle,
                  style: style,
                  formatFn: (segment) => {
                    return segment.title.split(" ");
                  },
                }}
                winModalTimeoutInMs={1000}
                wheelOptions={{
                  rootClass: "rootWheel",
                  skinPath: "/megapuesta/wheel/ruleta_de_la_fortuna.png",
                  width: width,
                  insideRadius: insideRadius,
                  borderColor: "yellow",
                  borderWidth: 3,
                  drawAngle: 180,
                  imageFrameClass: "wheel",
                }}
              >
                {(onSpin) => {
                  return (
                    <>
                      <button
                        ref={buttonRef}
                        className={"button"}
                        disabled={disabled}
                        onClick={() => {
                          onSpin();
                          setDisabled(true);
                        }}
                      >
                        GIRE LA RULETA
                      </button>
                    </>
                  );
                }}
              </WheelOfFortune>
            )}
          </>
        ) : (
          <NotAvailableModal
            playerId={options.playerId}
            campaignID={options.campaignId}
          />
        )}
      </div>
    </div>
  );
};
