"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_intl_1 = require("react-intl");
var context_1 = require("../contexts/appConfiguration/context");
var translations_1 = require("../utilities/translations");
function useTranslation(namespace) {
    var _a = (0, react_1.useState)(translations_1.defaultLanguage), lang = _a[0], setLang = _a[1];
    var language = (0, context_1.useAppConfig)().language;
    var changeLanguage = function (newLanguage) {
        if (newLanguage === void 0) { newLanguage = translations_1.defaultLanguage; }
        setLang(newLanguage);
    };
    var t = function (transId, values) {
        if (values === void 0) { values = {}; }
        return (0, jsx_runtime_1.jsx)(react_intl_1.FormattedMessage, { id: "".concat(namespace, ".").concat(transId), values: values });
    };
    var getTranslatedString = function (intl, transId, values) {
        if (values === void 0) { values = {}; }
        return intl.formatMessage({
            id: "".concat(namespace, ".").concat(transId),
            values: values,
        });
    };
    (0, react_1.useEffect)(function () {
        var selectedLanguage = language;
        if (!translations_1.languages.includes(selectedLanguage)) {
            selectedLanguage = translations_1.defaultLanguage;
        }
        setLang(selectedLanguage || translations_1.defaultLanguage);
    }, []);
    return { lang: lang, t: t, changeLanguage: changeLanguage, getTranslatedString: getTranslatedString };
}
exports.default = useTranslation;
;
