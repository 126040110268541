"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLootBox = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var actions_1 = require("./actions");
var reducer_1 = __importStar(require("./reducer"));
var Context = (0, react_1.createContext)({});
var Provider = Context.Provider;
var LootBoxProvider = function (_a) {
    var children = _a.children, miniGame = _a.miniGame, playerId = _a.playerId, campaignId = _a.campaignId, customerId = _a.customerId;
    var _b = (0, react_1.useReducer)(reducer_1.default, reducer_1.lootBoxInitialState), state = _b[0], dispatch = _b[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, actions_1.setActiveLootBox)(miniGame));
        dispatch((0, actions_1.setCampaignDetails)(playerId, campaignId, customerId));
    }, [miniGame]);
    return (0, jsx_runtime_1.jsx)(Provider, __assign({ value: { state: state, dispatch: dispatch } }, { children: children }));
};
exports.default = LootBoxProvider;
var useLootBox = function () { return (0, react_1.useContext)(Context); };
exports.useLootBox = useLootBox;
