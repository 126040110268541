import "@phantom/ehub-components/styles/races.css";
import { RaceLeaderboards } from "@phantom/ehub-components";
import "../styles/leaderboards.scss";
import { useMessages } from "../contexts/messages/context";

export const Leaderboards = () => {
  const { raceOptions, showGame } = useMessages();
  return (
    <div className={"leaderboards"}>
      {showGame && <RaceLeaderboards currency="RON" {...raceOptions} />}
    </div>
  );
};
